<template>
  <div>
    <ManageLotteryjapanOrder />
  </div>
</template>
<script>
import ManageLotteryjapanOrder from "@/components/Order/ManageLotteryjapanOrder";
export default {
  components: {
    ManageLotteryjapanOrder,
  },
  created() {
  },
};
</script>