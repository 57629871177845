<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>รายการสั่งซื้อ {{titleName}}</h3>
      </v-col>
      <v-card outlined class="ma-2 pa-2">
        <h2 style="font-weight: 800">การค้นหา</h2>
        <v-row align="center" justify="center" dense>
          <!-- <v-col cols="6" md="3" class="my-0 py-0">
            <span>หมายเลขคำสั่งซื้อ</span>
            <v-text-field
              label=""
              v-model="transactionNo"
              outlined
              dense
            ></v-text-field>
          </v-col> -->
          <!-- <v-col cols="6" md="3" class="my-0 py-0">
            <span>ชื่อจริง</span>
            <v-text-field
              label=""
              v-model="firstName"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3" class="my-0 py-0">
            <span>นามสกุล</span>
            <v-text-field
              label=""
              v-model="lastName"
              outlined
              dense
            ></v-text-field>
          </v-col> -->
          <!-- <v-col cols="6" md="3" class="my-0 py-0">
            <span>หมายเลขโทรศัพท์</span>
            <v-text-field
              label=""
              v-model="telNumber"
              outlined
              dense
            ></v-text-field>
          </v-col> -->

          <!-- <v-col cols="6" md="3" class="my-0 py-0">
            <v-checkbox
              v-model="isPowerPlay"
              label="isPowerPlay"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="6" md="3" class="my-0 py-0">
            <v-checkbox
              v-model="isPowerPlay2"
              label="สั่งซื้อล่วงหน้า"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="6" md="3" class="my-0 py-0">
            <span>ยอดเงินรวม</span>
            <v-text-field
              label=""
              v-model="totalPrice"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="my-0 py-0">
            <span>งวดวันที่</span>
            <v-menu
              v-model="menudrawDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="drawDate"
                  label=""
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="drawDate"
                @input="menudrawDate = false"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-col> -->

          <!-- <v-col cols="6" md="3" class="my-0 py-0">
            <span>วันที่เริ่ม</span>
            <v-menu
              v-model="menufrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datefrom"
                  label=""
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datefrom"
                @input="menufrom = false"
                no-title
                scrollable
                :min="firstDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="3" class="my-0 py-0">
            <span>วันที่สิ้นสุด</span>
            <v-menu
              v-model="menuto"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateto"
                  label=""
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateto"
                @input="menuto = false"
                no-title
                scrollable
                :min="firstDate"
              ></v-date-picker>
            </v-menu>
          </v-col> -->
          <!-- <v-col cols="4">
            <span>ค้นหาด้วยชื่อ</span>
            <v-text-field label="" outlined dense></v-text-field>
          </v-col> -->
          <v-col cols="6">
            <span>งวดที่ออก</span>
            <v-menu
              v-model="menufrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="drawDate"
                  label=""
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="drawDate"
                @input="menufrom = false"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-btn @click="clear()" class="mr-4">เคลียร์</v-btn>
            <v-btn @click="getAllOrder()" color="primary">ค้นหา</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <br />
      <v-tabs centered v-model="keytab">
        <v-tab v-for="item in tabitems" :key="item.key" @click="tab(item)">{{
          item.text
        }}</v-tab>
      </v-tabs>

      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <ExportOrderPowerBall :data="items" :titleName='titleName'/>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มหมวดหมู่</v-btn
            >
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersOrder"
            :items="items"
            :search="search"
            :items-per-page="50"
            class="elevation-1"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ convertDate(item.createdAt) }}น.</span>
            </template>

            <template v-slot:[`item.transactionNo`]="{ item }">
              <span style="color: red" v-if="item.refundPrice">{{
                item.transactionNo
              }}</span>
              <span v-else>{{ item.transactionNo }}</span>
            </template>
            <!-- refundPrice -->
            <!-- <template v-slot:[`item.isDelivery`]="{ item }">
              <span v-if="item.isDelivery">จัดส่งตามที่อยู่</span>
              <span v-if="!item.isDelivery">เก็บในตู้เซพ</span>
            </template> -->

            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status == 'รอคอนเฟิร์ม'" color="orange" dark>
                รอยืนยันสลิป
              </v-chip>
              <!-- <v-chip
                v-if="item.status == 'ยืนยันสลิปสำเร็จ'"
                color="green"
                dark
              >
                ยืนยันสลิปสำเร็จ
              </v-chip>
              <v-chip
                v-if="item.status == 'ยังไม่ได้อัพโหลดสลิป'"
                color="blue"
                dark
              >
                ยังไม่ได้อัพโหลดสลิป
              </v-chip> -->
              <v-chip v-if="item.status == 'สั่งซื้อสำเร็จ'" color="green" dark>
                สั่งซื้อสำเร็จ
              </v-chip>
              <v-chip v-if="item.status == 'รอการชำระเงิน'" color="red" dark>
                รอการชำระเงิน
              </v-chip>
              <v-chip
                v-if="item.status == 'ชำระเงินสำเร็จ'"
                color="orange"
                dark
              >
                ชำระเงินสำเร็จ
              </v-chip>
              <v-chip v-if="item.status == 'มีปัญหา'" color="red" dark>
                มีปัญหา
              </v-chip>
              <v-chip v-if="item.status == 'ยกเลิก'" color="brown" dark>
                ยกเลิก
              </v-chip>
              <!-- <v-chip v-if="item.status == 'ถูกจัดส่งแล้ว'" color="green" dark
                ><v-icon>mdi-check-circle</v-icon>{{ item.status }}</v-chip
              > -->
            </template>

            <template v-slot:[`item.buyToFlag`]="{ item }">
              <span v-if="item.buyToFlag == 1">ใช่</span>
              <span v-else>ไม่ใช่</span>
            </template>
            <template v-slot:[`item.agentId`]="{ item }">
              <span v-if="item.agentId">ใช่</span>
              <span v-else>ไม่ใช่</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="viewOrder(item)">mdi-eye</v-icon> -->
                <v-icon @click="UpdateOrder(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <!-- <v-icon @click="DeleteOrder(item)">mdi-delete</v-icon> -->
              </v-row>
            </template>
            <template v-slot:[`item.powerballStatus`]="{ item }">
             
              <v-chip v-if="!item.powerballStatus" color="orange" dark>
                รอสถานะจากGSPL
              </v-chip>
              <v-chip v-if="item.powerballStatus == 'Confirmed'" color="green" dark>
                {{item.powerballStatus}}
              </v-chip>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import moment from "moment";
import { Decode, Encode } from "@/services";
import ExportOrderPowerBall from "@/components/ExportExcel/ExportOrderPowerBall";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: { ExportOrderPowerBall, Loading },
  data() {
    return {
      titleName:'Mini Loto Japan',
      keytab: 0,
      loading: false,
      headersOrder: [
        // ลำดับ หมายเลขคำสั่งซื้อ สถานะ ตัวเลือก วันที่สั่ง ชื่อผู้สั่ง หมายเลขโทรศัพท์ ราคา PowerPlay เลขที่ซื้อ ออเดอร์ตัวแทน รับผิดชอบโดย
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "หมายเลขคำสั่งซื้อ", value: "transactionNo", align: "center" },
        { text: "สถานะ", value: "status", align: "center" },
        { text: "สถานะ MINI LOTTO", value: "powerballStatus", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
        { text: "วันที่สั่ง", value: "createdAt", align: "center" },
        { text: "ชื่อผู้สั่ง", value: "fullname", align: "center" },
        { text: "หมายเลขโทรศัพท์", value: "user.telNumber", align: "center" },
        { text: "ราคา", value: "totalPrice", align: "center" },
        // { text: "PowerPlay", value: "isPowerPlay", align: "center" },
        {
          text: "เลขที่ซื้อ",
          value: "selectedToStr",
          align: "center",
          width: "200px",
        },
        { text: "ยอดเงินคืน", value: "refundPrice", align: "center" },
        { text: "ซื้อให้คนอื่น", value: "buyToFlag", align: "center" },
        { text: "ออเดอร์ตัวแทน", value: "agentId", align: "center" },
        { text: "รับผิดชอบโดย", value: "adminName", align: "center" },
      ],
      // adminName: (...)
      // agentId: (...)
      // buyToFlag: (...)
      // buyType: (...)
      // count: 1
      // createdAt: (...)
      // drawDate: (...)
      // fullname: "สำราญ ช้างวงษ์"
      // globalId: (...)
      // id: (...)
      // isPowerPlay: (...)
      // multipleAmt: (...)
      // multipleAmtLeft: (...)
      // paymentType: (...)
      // paysolutionId: (...)
      // powerballPostParam: (...)
      // receiveFirstName: (...)
      // receiveFromFirstName: (...)
      // receiveFromLastName: (...)
      // receiveFromTel: (...)
      // receiveLastName: (...)
      // receiveTel: (...)
      // remark: (...)
      // selectedBall: (...)
      // selectedToStr: "[10,21,22,8,9 *3*]\n[1,22,26,5,60 *10*]\n"
      // seletedSetObj: (2) [{…}, {…}]
      // slipImgUrl: (...)
      // status: (...)
      // totalPrice: (...)
      // transactionNo: (...)
      // updatedAt: (...)
      // user: (...)
      // userId: (...)
      items: [
        // { category: "promotion", description: "สินค้าจัดโปร" },
        // { category: "discount items", description: "สินค้าลดราคา" },
        // { category: "Shock price", description: "สินค้าราคาถูก" },
        // { category: "สินค้ามาใหม่", description: "สินค้ามาใหม่" },
      ],
      tabitems: [
        { text: "ทั้งหมด", key: "", index: 0 },
        { text: "รอยืนยันสลิป", key: "รอคอนเฟิร์ม", index: 1 },
        { text: "รอการชำระเงิน", key: "รอการชำระเงิน", index: 2 },
        { text: "ชำระเงินสำเร็จ", key: "ชำระเงินสำเร็จ", index: 3 },
        { text: "สั่งซื้อสำเร็จ", key: "สั่งซื้อสำเร็จ", index: 4 },
        { text: "มีปัญหา", key: "มีปัญหา", index: 5 },
        { text: "ยกเลิก", key: "ยกเลิก", index: 6 },
      ],
      search: "",
      count: 0,

      selectedindex: 0,
      transactionNo: "",
      // firstName: "",
      // lastName: "",
      // telNumber: "",

      totalPrice: "",
      isPowerPlay: "",
      isPowerPlay2: false,
      drawDate: "",
      menudrawDate: false,
      datefrom: new Date().toISOString().substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
      firstDate: new Date("2021-12-01").toISOString().slice(0, 10),
    };
  },
  watch: {
    keytab(val) {
      if (val) {
        console.log("watch", val);
      }
    },
  },
  created() {
    this.keytab = parseInt(this.$route.query.tab ? this.$route.query.tab : 0);

    this.getData();
  },
  methods: {
    async getData() {
      let keytab = 0;
      if (this.keytab == 0) {
        keytab = "";
      } else if (this.keytab == 1) {
        keytab = "รอคอนเฟิร์ม";
      } else if (this.keytab == 2) {
        keytab = "รอการชำระเงิน";
      } else if (this.keytab == 3) {
        keytab = "ชำระเงินสำเร็จ";
      } else if (this.keytab == 4) {
        keytab = "สั่งซื้อสำเร็จ";
      } else if (this.keytab == 5) {
        keytab = "มีปัญหา";
      } else if (this.keytab == 6) {
        keytab = "ยกเลิก";
      }

      if (this.keytab == "") {
        this.getAllOrder();
      } else {
        // console.log('this.keytab',this.keytab);
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        console.log(user);
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/transactionLotteryjapan/getAllPowerballTrx?status=keytab&drawDate=${this.drawDate}?status=${keytab}&drawDate=${this.drawDate}`,

          // "&customerName=" +
          // this.customerName +
          // "&drawDate=" +
          // this.drawDate,
          auth
        );
        this.items = response.data.data;
        for (let i in this.items) {
          this.items[i].count = parseInt(i) + 1;
          this.items[i].fullname =
            this.items[i].user.firstName + " " + this.items[i].user.lastName;

          this.items[i].seletedSetObj = JSON.parse(this.items[i].selectedBall);
          // console.log(this.items[i].seletedSetObj);
          let selectedToStr = "";
          for (
            let index = 0;
            index < this.items[i].seletedSetObj.length;
            index++
          ) {
            const element = this.items[i].seletedSetObj[index];
            selectedToStr += `[` + element.set + ` ]\n`;
          }

          // console.log(selectedToStr);
          this.items[i].selectedToStr = selectedToStr;
        }
        // console.log("response", response.data.data);
      }
    },
    clear() {
      this.transactionNo = "";
      this.firstName = "";
      this.lastName = "";
      this.telNumber = "";
      this.totalPrice = "";
      this.isPowerPlay = false;
      this.drawDate = "";
      this.getAllOrder();
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY HH:mm");
    },
    async tab(val) {
      this.selectedindex = val.index;
      console.log("ในtab", this.selectedindex);
      if (val.key == "") {
        this.getAllOrder();
      } else {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        console.log(user);
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/transactionLotteryjapan/getAllPowerballTrx?status=${val.key}&drawDate=${this.drawDate}`,
          // "&customerName=" +
          // this.customerName +
          // "&drawDate=" +
          // this.drawDate,
          auth
        );
        this.items = response.data.data;
        for (let i in this.items) {
          this.items[i].count = parseInt(i) + 1;
          this.items[i].fullname =
            this.items[i].user.firstName + " " + this.items[i].user.lastName;

          this.items[i].seletedSetObj = JSON.parse(this.items[i].selectedBall);
          console.log(this.items[i].seletedSetObj);
          let selectedToStr = "";
          for (
            let index = 0;
            index < this.items[i].seletedSetObj.length;
            index++
          ) {
            const element = this.items[i].seletedSetObj[index];
            selectedToStr += `[` + element.set + ` ]\n`;
          }

          console.log(selectedToStr);
          this.items[i].selectedToStr = selectedToStr;
        }
        console.log("response", response.data.data);
      }
    },
    async getAllOrder() {
      this.loading = true;
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      console.log(user);
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transactionLotteryjapan/getAllPowerballTrx?status=&drawDate=${this.drawDate}`,
        // "?transactionNo=" +
        // this.transactionNo +
        // "?totalPrice=" +
        // this.totalPrice +
        // "&isPowerPlay=" +
        // this.isPowerPlay +
        // "&drawDate=" +
        // this.drawDate +
        // "&createdAt=" +
        // this.dateForm +
        // "&createdAt=" +
        // this.dateTo +

        // "&drawDate=" +
        // this.drawDate
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("getAllOrder", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
        this.items[i].fullname =
          this.items[i].user.firstName + " " + this.items[i].user.lastName;

        this.items[i].seletedSetObj = JSON.parse(this.items[i].selectedBall);
        console.log(this.items[i].seletedSetObj);
        let selectedToStr = "";
        for (
          let index = 0;
          index < this.items[i].seletedSetObj.length;
          index++
        ) {
          const element = this.items[i].seletedSetObj[index];
          selectedToStr += `[` + element.set + ` ]\n`;
        }

        console.log(selectedToStr);
        this.items[i].selectedToStr = selectedToStr;
        // this.items[i].buyLotNo = "";
      }

      console.log(this.items);
      this.loading = false;
    },
    UpdateOrder(val) {
      localStorage.setItem("orderdata", Encode.encode(val));
      this.$router.push({
        path: "/EditPowerballOrder",
        query: {
          tab: this.selectedindex,
          transactionType:'transactionLotteryjapan'
        },
      });
    },
    async DeleteOrder(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("DDLotAdmin"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/transactionLotteryjapan/` + val.id,
            auth
          );
          console.log(response);
          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllOrder();
        }
      });
    },
    goToCreate() {
      this.$router.push("createcategory");
    },
  },
};
</script>
